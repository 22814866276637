import React from "react"
import { graphql } from "gatsby"
import Layout from "../modules/layout"
import SEO from "../modules/seo"
import Post from "../components/post"

const Blog = ({ data }) => {
  console.log(data)
  return (
    <Layout theme="smartwerker" lang="de">
      <SEO title="Blog" />
      {data.allContentfulPost.nodes.map((item, index) => (
        <Post
          key={index}
          additionalClass="color_invert"
          title={item.postTitle}
          image={item.postImage}
          shortDesc={item.postShortDescription}
          postURLSlug={item.postUrlSlug}
        />
      ))}
    </Layout>
  )
}

export const pageQuery = graphql`
query blogQuery {
  allContentfulPost {
    nodes {
      postUrlSlug
      postTitle
      postShortDescription
      postContent {
        json
      }
      postImage {
        fluid {
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
          aspectRatio
        }
      }
    }
  }
}
`

export default Blog
