import React from "react"
import { Container } from "react-bootstrap"
import Image from "gatsby-image"
import { Link } from "gatsby"

const Post = ({ title, shortDesc, postURLSlug, style, image, additionalClass }) => (
  <section className={`component post py-5`} style={style}>
    <Container fluid className={`${additionalClass}`}>
      <div className="row">
        <div className="col-12 col-md-4">
          <Image fluid={image.fluid} />
        </div>
        <div className="col-12 col-md-8">
          <h2 className="h3">{title}</h2>
          <p>{shortDesc}</p>
          <Link to={`/blog/${postURLSlug}`} className="btn btn-dark">Weiterlesen</Link>
        </div>
      </div>
    </Container>
  </section>
)

export default Post
